import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/home';
import Terms from './components/terms';
import Logo from './images/cc-logo.png';
import { useEffect, useState } from 'react';
import axios from './Axios';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AplicationSucesful from './components/aplicationSucesfull';
import AccessExpired from './components/accessExpired';
import GameNotStarted from './components/gameNotStarted';

function App() {
  const [labels, setLabels] = useState([]);

  const getLabels = () => {
    axios.get('/general/labels')
      .then(data => {
        setLabels(data.data);
      }).catch(err => {
        // console.log('labelserr', err);
      });
  };

  useEffect(() => {
    getLabels();
  }, []);

  const nextYear = "2024-12-25";
  const gameStartDate = "2024-11-25";
  const currentDate = new Date();
  const cutoffDate = new Date(nextYear);
  const gamStartedDate = new Date(gameStartDate);

  const GameEnded = currentDate > cutoffDate
  // const GameStarted = currentDate < gamStartedDate;
  const GameStarted = false;
  
  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {
        GameStarted ? <GameNotStarted />
          :
          GameEnded ? <AccessExpired />
            :
            <Router>
              <Routes>
                <Route exact path="/" element={<Home labels={labels} />} />
                <Route exact path="/success" element={<AplicationSucesful labels={labels} />} />
                <Route exact path="/terms" element={<Terms labels={labels} />} />
              </Routes>
            </Router>
      }
      <div className='footer mb-4'>
        <div className='footerSigniture'>©{new Date().getFullYear()} The Coca-Cola Company.</div>
        <div className='footerLogo'><img alt='logo' src={Logo} /></div>
      </div>
    </div>
  );
}

export default App;
